/* You can add global styles to this file, and also import other style files */
//@import "~bootstrap/scss/bootstrap.scss";

//@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

//@import "../node_modules/@swimlane/ngx-datatable/index.css";
//@import "../node_modules/@swimlane/ngx-datatable/themes/bootstrap.css";
//@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";
//@import "../node_modules/@swimlane/ngx-datatable/themes/material.css";

/* ngx-chips スタイル上書き */
.ng2-tag-input {
  border: none !important;
}

.ng2-tag-input__text-input {
  background-color: transparent;
}

.ng2-tag-input.ng2-tag-input--focused {
  border: none !important;
}

.fc-col-header-cell-cushion {
  color: #212529;
  text-decoration: none;
}

.fc-day-today {
  background-color:inherit !important;
}

.fc-day-sat a {
  color: #0d6efd;
}

.fc-day-sun a {
  color: #dc3545;
}

.row-disabled {
  background-color: #dddddd;
}

@media (min-width: 768px) {
  .col-form-label {
    text-align: center;
  }
}
